import {
  RESTAURANT_DETAILS,
  RESTAURANT_DETAILS_BY_ID,
  OFFERS_DETAILS,
  MENU_DETAILS,
} from "redux/constants/RestaurantConstants";
import { AUTH_TOKEN } from "redux/constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const RestaurantReducers = (state = initState, action) => {
  switch (action.type) {
    case RESTAURANT_DETAILS:
      return {
        ...state,
        redirect: "",
        restaurantData: action.payload,
        token: action.token,
      };

    case RESTAURANT_DETAILS_BY_ID:
      return {
        ...state,
        redirect: "",
        restaurantDataById: action.payload,
        token: action.token,
      };

    case OFFERS_DETAILS:
      return {
        ...state,
        redirect: "",
        offersData: action.payload,
        token: action.token,
      };

    case MENU_DETAILS:
      return {
        ...state,
        redirect: "",
        menuData: action.payload,
        token: action.token,
      };

    default:
      return state;
  }
};

export default RestaurantReducers;
