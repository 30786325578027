import { CATEGORY_DETAILS } from "redux/constants/CategoryConstants";
import { AUTH_TOKEN } from "redux/constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const CategoryReducers = (state = initState, action) => {
  switch (action.type) {
    case CATEGORY_DETAILS:
      return {
        ...state,
        redirect: "",
        categoryData: action.payload,
        token: action.token,
      };

    default:
      return state;
  }
};

export default CategoryReducers;
