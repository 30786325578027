export const LIST_RESTAURANT = "LIST_RESTAURANT";

export const RESTAURANT_DETAILS = "RESTAURANT_DETAILS";

export const LIST_RESTAURANT_BY_ID = "LIST_RESTAURANT_BY_ID";

export const RESTAURANT_DETAILS_BY_ID = "RESTAURANT_DETAILS_BY_ID";

export const LIST_OFFERS = "LIST_OFFERS";

export const OFFERS_DETAILS = "OFFERS_DETAILS";

export const LIST_MENU = "LIST_MENU";

export const MENU_DETAILS = "MENU_DETAILS";

