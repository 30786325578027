import { all, takeEvery, put, fork } from "redux-saga/effects";
import {
  LIST_MENU,
  LIST_OFFERS,
  LIST_RESTAURANT,
  LIST_RESTAURANT_BY_ID,
} from "redux/constants/RestaurantConstants";
import {
  menuDetails,
  offersDetails,
  restaurantDetails,
  restaurantDetailsById,
} from "redux/actions/RestaurantActions";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
// let rest_id = "";
// if (userD) {
//   rest_id = userD.restaurant_id;
// }

//console.log("rest_id", userD.restaurant_id);
//`/discoveryapp_restaurants/${userD.restaurant_id}/${userD.id}`

export function* getRestaurant() {
  yield takeEvery(LIST_RESTAURANT, function* () {
    try {
      const res = yield axios.get(`/discoveryapp_restaurants`);
      if (res.status === 200) {
        yield put(restaurantDetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getRestaurantById() {
  yield takeEvery(LIST_RESTAURANT_BY_ID, function* ({ payload }) {
    try {
      const res = yield axios.get(
        `/discoveryapp_restaurants/${payload}/${userD.id}`
      );
      if (res.status === 200) {
        yield put(restaurantDetailsById(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getOffers() {
  yield takeEvery(LIST_OFFERS, function* ({ payload }) {
    try {
      const res = yield axios.get(`/get_restaurant_offer/${payload}`);
      if (res.status === 200) {
        yield put(offersDetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getMenu() {
  yield takeEvery(LIST_MENU, function* ({ payload }) {
    try {
      const res = yield axios.get(`/restaurant_menu/${payload}`);
      if (res.status === 200) {
        yield put(menuDetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getRestaurant),
    fork(getRestaurantById),
    fork(getOffers),
    fork(getMenu),
  ]);
}
