import { LIST_TAGS, TAGS_DETAILS } from "redux/constants/TagsConstants";

export const tagsList = () => {
  return {
    type: LIST_TAGS,
  };
};

export const tagsDetails = (data) => {
  return {
    type: TAGS_DETAILS,
    payload: data,
  };
};
