import {
  LIST_CATEGORY,
  CATEGORY_DETAILS,
} from "../constants/CategoryConstants";

export const categoryList = () => {
  return {
    type: LIST_CATEGORY,
  };
};

export const categoryDetails = (data) => {
  return {
    type: CATEGORY_DETAILS,
    payload: data,
  };
};
