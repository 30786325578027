import { all } from "redux-saga/effects";
import Auth from "./Auth";
import CategoryApi from "./CategoryApi";
import RestaurantApi from "./RestaurantApi";
import TagsApi from "./TagsApi";
import ReservationApi from './ReservationApi'

export default function* rootSaga(getState) {
  yield all([Auth(), CategoryApi(), RestaurantApi(), TagsApi(), ReservationApi(),]);
}
