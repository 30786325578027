import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import CategoryReducers from "./CategoryReducers";
import RestaurantReducers from "./RestaurantReducers";
import TagsReducers from "./TagsReducers";
import ReservationReducers from "./ReservationReducers";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  category: CategoryReducers,
  restaurant: RestaurantReducers,
  tags: TagsReducers,
  reservation: ReservationReducers,
});

export default reducers;
