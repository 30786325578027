import {
  UPCOMING_DETAILS,
  COMPLETED_DETAILS,
  ADD_RESERVATION,
  RESERVATION,
  TIME_DETAILS
} from "redux/constants/ReservationConstants";
import { AUTH_TOKEN } from "redux/constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const ReservationReducers = (state = initState, action) => {
  switch (action.type) {
    case ADD_RESERVATION:
      return {
        ...state,
        redirect: "",
        token: action.token,
        reservationData: action.reservationData,
      };
    case RESERVATION:
      return {
        ...state,
        redirect: "",
        token: action.token,
        reservationData: action.payload,
      };
    case UPCOMING_DETAILS:
      return {
        ...state,
        redirect: "",
        upcomingData: action.payload,
        token: action.token,
      };
    case COMPLETED_DETAILS:
      return {
        ...state,
        redirect: "",
        completedData: action.payload,
        token: action.token,
      };

      case TIME_DETAILS:
        return {
          ...state,
          redirect: "",
          timeData: action.payload,
          token: action.token,
        };
    default:
      return state;
  }
};

export default ReservationReducers;
