import { all, takeEvery, put, fork } from "redux-saga/effects";
import { LIST_CATEGORY } from "redux/constants/CategoryConstants";
import { categoryDetails } from "redux/actions/CategoryActions";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* getCategory() {
    yield takeEvery(LIST_CATEGORY, function* () {
      try {
        const res = yield axios.get(`/get_restaurant_type`);
        if (res.status === 200) {
          yield put(categoryDetails(res.data));
        } else {
          // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
          // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
        }
      } catch (err) {
        console.log("Err", err);
      }
    });
  }

  export default function* rootSaga() {
    yield all([
      fork(getCategory),
    ]);
  }