import {
  LIST_RESTAURANT,
  RESTAURANT_DETAILS,
  LIST_RESTAURANT_BY_ID,
  RESTAURANT_DETAILS_BY_ID,
  LIST_OFFERS,
  OFFERS_DETAILS,
  LIST_MENU,
  MENU_DETAILS,
} from "redux/constants/RestaurantConstants";

export const restaurantList = () => {
  return {
    type: LIST_RESTAURANT,
  };
};

export const restaurantDetails = (data) => {
  return {
    type: RESTAURANT_DETAILS,
    payload: data,
  };
};

export const restaurantListById = (data) => {
  return {
    type: LIST_RESTAURANT_BY_ID,
    payload: data,
  };
};

export const restaurantDetailsById = (data) => {
  return {
    type: RESTAURANT_DETAILS_BY_ID,
    payload: data,
  };
};

export const offersList = (data) => {
  return {
    type: LIST_OFFERS,
    payload: data,
  };
};

export const offersDetails = (data) => {
  return {
    type: OFFERS_DETAILS,
    payload: data,
  };
};

export const menuList = (data) => {
  return {
    type: LIST_MENU,
    payload: data,
  };
};

export const menuDetails = (data) => {
  return {
    type: MENU_DETAILS,
    payload: data,
  };
};
