import { all, takeEvery, put, fork } from "redux-saga/effects";
import {
  LIST_UPCOMING,
  LIST_COMPLETED,
  ADD_RESERVATION,
  LIST_TIME,
} from "redux/constants/ReservationConstants";
import {
  upcomingDetails,
  completedDetails,
  timeDetails,
} from "redux/actions/ReservationActions";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

let phone;

if (userD) {
  phone = userD.phone;
}
console.log("phone", phone);

export function* getUpcoming() {
  yield takeEvery(LIST_UPCOMING, function* () {
    try {
      const res = yield axios.get(`/get_user_reservation/${phone}`);
      if (res.status === 200) {
        yield put(upcomingDetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getCompleted() {
  yield takeEvery(LIST_COMPLETED, function* () {
    try {
      const res = yield axios.get(`/get_restaurant_type`);
      if (res.status === 200) {
        yield put(completedDetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* insertReservationData() {
  yield takeEvery(ADD_RESERVATION, function* ({ payload }) {
    try {
      const res = yield axios.post(
        `/add_reservation_pandora/${payload.rest_id}`,
        payload.data
      );
      if (res.data.message === 1) {
        message.error("Your Reservation For This Time Already Exists");
      } else if (res.data.status === 200) {
        message.success("Your Reservation is Successfully done.");
      } else {
        message.error("Somthing Went Wrong Please wait");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getReservationTime() {
  yield takeEvery(LIST_TIME, function* ({ payload }) {
    try {
      const res = yield axios.post(
        `/get_reservation_time/${payload.rest_id}`,
        payload.data
      );

      if (res.status === 200) {
        yield put(timeDetails(res.data));
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getUpcoming),
    fork(getCompleted),
    fork(insertReservationData),
    fork(getReservationTime),
  ]);
}
