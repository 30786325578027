import {
  LIST_UPCOMING,
  UPCOMING_DETAILS,
  LIST_COMPLETED,
  COMPLETED_DETAILS,
  ADD_RESERVATION,
  LIST_TIME,
  TIME_DETAILS,
} from "redux/constants/ReservationConstants";

export const addreservation = (data) => {
  return {
    type: ADD_RESERVATION,
    payload: data,
  };
};

export const upcomingList = () => {
  return {
    type: LIST_UPCOMING,
  };
};

export const upcomingDetails = (data) => {
  return {
    type: UPCOMING_DETAILS,
    payload: data,
  };
};

export const completedList = () => {
  return {
    type: LIST_COMPLETED,
  };
};

export const completedDetails = (data) => {
  return {
    type: COMPLETED_DETAILS,
    payload: data,
  };
};

export const timeList = (data) => {
  return {
    type: LIST_TIME,
    payload: data,
  };
};

export const timeDetails = (data) => {
  return {
    type: TIME_DETAILS,
    payload: data,
  };
};
