export const LIST_UPCOMING = "LIST_UPCOMING";

export const UPCOMING_DETAILS = "UPCOMING_DETAILS";

export const LIST_COMPLETED = "LIST_COMPLETED";

export const COMPLETED_DETAILS = "COMPLETED_DETAILS";

export const RESERVATION = "RESERVATION";

export const ADD_RESERVATION = "ADD_RESERVATION";

export const LIST_TIME = "LIST_TIME";

export const TIME_DETAILS = "TIME_DETAILS";
